import { formatMessage } from '@theorchard/suite-frontend';
import { useLocation } from 'react-router-dom';

export const Wrapper = ({ children }: { children?: React.ReactNode }) => {
    const uncontrollableRoutes = ['/account'];

    const { pathname } = useLocation();

    if (uncontrollableRoutes.some(route => route === pathname)) {
        document.title = formatMessage('pages.defaultTitle');
    }

    return <>{children}</>;
};
