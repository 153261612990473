import type { CheckPermission, Permission } from '../types';

export const checkPermission =
    (permissions: Permission[]): CheckPermission =>
    (resourceType, action) => {
        const permission = permissions.find(
            permission =>
                permission.action === action &&
                permission.resourceType === resourceType
        );

        return Boolean(permission?.tenants && permission.tenants.length > 0);
    };
