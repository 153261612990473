import { RESOURCE_TYPE_ACTIONS } from '../../permissions/constants';
import { checkPermission } from './utils/checkPermission';
import { getAuthorizedTenants } from './utils/getAuthorizedTenants';
import { getTenantRoles } from './utils/getTenantRoles';
import type { Permission, PermissionsPluginData } from './types';
import type {
    GraphqlClient,
    OnAppDataLoadCallback,
} from '@theorchard/suite-frontend';

export const getPermissions = (async ({
    identity,
    graphqlClient,
}: {
    identity: { id: string };
    graphqlClient: GraphqlClient;
}): Promise<PermissionsPluginData> => {
    const tenantRoles = await getTenantRoles({
        identityId: identity.id,
        graphqlClient,
    });

    const resourcesAuthorizedTenants = await getAuthorizedTenants({
        identityId: identity.id,
        resourceTypeActions: RESOURCE_TYPE_ACTIONS,
        graphqlClient,
    });

    const permissions = resourcesAuthorizedTenants.map<Permission>(
        resourceTypeActionTenants => {
            return {
                action: resourceTypeActionTenants.action,
                resourceType: resourceTypeActionTenants.resourceType,
                tenants: resourceTypeActionTenants.tenants.map(tenant => ({
                    uuid: tenant.uuid,
                    __typename: tenant.__typename,
                })),
            };
        }
    );

    return {
        tenantRoles,
        permissions,
        hasPermission: checkPermission(permissions),
    };
}) satisfies OnAppDataLoadCallback<PermissionsPluginData>;
