import QUERY from 'src/apollo/queries/tenantRoles/tenantRoles.gql';
import type { ResourceTypeAction } from '../types';
import type { GraphqlClient } from '@theorchard/suite-frontend';
import type {
    TenantRolesQuery as Query,
    TenantRolesQueryVariables as Variables,
} from 'src/apollo/definitions/tenantRoles';

export const getAuthorizedTenants = async ({
    identityId,
    resourceTypeActions,
    graphqlClient,
}: {
    identityId: string;
    resourceTypeActions: ResourceTypeAction[];
    graphqlClient: GraphqlClient;
}) => {
    const result = await graphqlClient.query<Query, Variables>({
        query: QUERY,
        variables: { identityId, resourceTypeActions },
    });

    const authorizedTenants =
        result.data.identityById?.isAuthorizedForTenants ?? [];

    return authorizedTenants;
};

export type AuthorizedTenants = Awaited<
    ReturnType<typeof getAuthorizedTenants>
>[number];
