export const RESOURCE_TYPE_ACTIONS: { resourceType: string; action: string }[] =
    [
        {
            resourceType: 'ad_campaign',
            action: 'view',
        },
        {
            resourceType: 'ad_connection',
            action: 'connect',
        },
    ];
